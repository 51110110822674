<template>
  <v-toolbar color="grey darken-3" height="140" flat>
    <div>
    <v-row class="mt-n3">
      <v-col>
        <v-select 
            v-model="selectedHighway"
            :items="highwayItems"
            clearable
            item-value="id"
            item-text="name"
            dense
            label="Autopista"
            solo
            class="mt-3"/>
      </v-col>
      <v-col>
        <v-select
            v-model="selectedType"
            :items="typeItems"
            clearable
            item-value="id"
            item-text="description"
            dense
            label="Tipo"
            solo
            class="mt-3"/>
      </v-col>
      <v-col>
        <v-select 
            v-model="selectedStatus"
            :items="statusItems"
            clearable
            item-value="id"
            item-text="name"
            dense
            label="Estatus"
            solo
            class="mt-3"/>
      </v-col>
      <v-col>
        <v-select
            v-model="selectedOrigin"
            :items="originItems"
            clearable
            item-value="id"
            item-text="name"
            dense
            label="Origen"
            solo
            class="mt-3"/>
      </v-col>
      <v-col>
        <v-menu
            ref="startMenu"
            v-model="startMenu"
            :close-on-content-click="false"
            :return-value.sync="startDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="startDate"
                label="Rango fecha inicial"
                class="mt-3"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                solo
                clearable
            >
              <template v-slot:prepend>
                <v-icon
                    class="material-icons-round mt-1"
                    color="#adb5bd">
                  calendar_month
                </v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
              v-model="startDate"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="startMenu = false"
            >
              Cancelar
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.startMenu.save(startDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
            ref="endMenu"
            v-model="endMenu"
            :close-on-content-click="false"
            :return-value.sync="endDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="endDate"
                label="Rango fecha final"
                class="mt-3"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                solo
                clearable
            >
              <template v-slot:prepend>
                <v-icon
                    class="material-icons-round mt-1"
                    color="#adb5bd">
                  calendar_month
                </v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
              v-model="endDate"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="endMenu = false"
            >
              Cancelar
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.endMenu.save(endDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>      
      <v-col>
        <v-btn color="primary" class="float-right mt-3" @click="newIncident()">
          Nueva Incidencia
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="10">
        <v-text-field
            v-model="search"
            class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
            dense
            filled
            flat
            hide-details
            placeholder="Buscar..."
            solo
            full-width
        >
          <template slot="prepend-inner">
            <v-icon
                class="material-icons-round mt-n2"
                color="#adb5bd"
                size="18px"
            >search
            </v-icon
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="2" class="pr-2">
        <v-btn :loading="isRequestingReport" color="blue-grey lighten-4" height="40" block class="ml-3" @click="requestReport()">
          Solicitar Reporte
        </v-btn>
      </v-col>
    </v-row>
    </div>
  </v-toolbar>
</template>

<script>
import catalogsService from "@/services/catalogs.service";
import incidentService from "@/services/incident.service";

export default {
  name: "IncidentsTableToolbar",
  data:() =>({
    isRequestingReport: false,
    highwayItems:[],
    typeItems: [],
    statusItems:[],
    originItems: [
      {
        id:"AAS",
        name:"AAS"
      },
      {
        id:"MRO",
        name:"MRO"
      },
    ],
    selectedHighway: null,
    selectedType: null,
    selectedStatus: null,
    selectedOrigin: null,
    search: "",
    startDate: null,
    endDate: null,
    startMenu: false,
    endMenu: false,
  }),
  watch:{
    search(){
        this.filterSelected();
    },
    selectedHighway(){
        this.filterSelected();
    },
    selectedStatus(){
        this.filterSelected();
    },
    selectedOrigin(){
      this.filterSelected();
    },
    selectedType(){
      this.filterSelected();
    },
    startDate(){
      this.filterSelected();
    },
    endDate(val){
      this.filterSelected();
    }
  },
  mounted() {
    this.loadHighways();
    this.loadTypes();
    this.loadStatus();
  },
  methods:{
    loadHighways(){
      catalogsService.getHigways(0,100).then(d =>{
        this.highwayItems = d.items;
      });
    },
    loadTypes(){
      incidentService.getTypes().then(d =>{
        this.typeItems = d.items;
      })
    },
    loadStatus(){
      incidentService.getStatuses().then(d =>{
        this.statusItems = d.items;
      })
    },
    filterSelected(){
      this.$emit("on-filter-selected", {
        highwayId: this.selectedHighway,
        statusId: this.selectedStatus,
        origin: this.selectedOrigin,
        search: this.search,
        startDate: this.startDate,
        endDate: this.endDate,
        text: this.search.length > 2 ? this.search : "",
      });
    },
    newIncident(){
      this.$emit("on-new-incident", true);
    },
    requestReport(){
      this.isRequestingReport = true;
      
      incidentService.requestReport({
        highwayId: this.selectedHighway,
        statusId: this.selectedStatus,
        origin: this.selectedOrigin,
        search: this.search,
        startDate: this.startDate,
        endDate: this.endDate,
        text: this.search.length > 2 ? this.search : "",
      }).then(d =>{
        this.$swal(`El reporte de incidencias sera enviado a [${d.email}]`,`${d.uniqueRequest}`,"success").then(() =>{
          this.isRequestingReport = false;
        });
      })
    }
  }
}
</script>

<style scoped>

</style>
