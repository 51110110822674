import http, { e } from './http'
import loginService from './login.service'

const incidentService = {
  getIncidents: function (data) {
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e('app/incident'), data, headers).then(r => {
      return r.data
    })
  },
  getStatuses(){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e('app/incident/statuses'), null, headers).then(r => {
      return r.data
    })
  },
  getStatusesByCurrent(currentStatus){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e(`app/incident/statuses?currentStatus=${currentStatus}`), null, headers).then(r => {
      return r.data
    })
  },
  getTypes(){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e('app/incident/types'), null, headers).then(r => {
      return r.data
    })
  },
  getIncident(incidentId){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e(`app/incident/${incidentId}`), null, headers).then(r => {
      return r.data
    })
  },
  updateStatus(incidentId, data){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.put(e(`app/incident/status/${incidentId}`), data, headers).then(r => {
      return r.data
    })
  },
  createIncident(data){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.post(e(`app/incident`), data, headers).then(r => {
      return r.data
    })
  },
  requestReport(data){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.post(e(`app/incident/request-report`), data, headers).then(r => {
      return r.data
    })
  },
  getIncidentsStatusCounter(){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e(`app/incident/incidents-status-counter`), null, headers).then(r => {
      return r.data
    })
  },
  getIncidentsTypeCounter(incidentTypeId){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.get(e(`app/incident/incidents-types-counter/${incidentTypeId}`), null, headers).then(r => {
      return r.data
    })
  },
  deleteIncident(incidentId){
    const token = loginService.getRawToken()

    const headers = {
      Authorization: 'Bearer ' + token,
    };

    return http.remove(e(`app/incident/${incidentId}`), headers).then(r => {
      return r.data
    })
  }
}

export default incidentService
