<template>
  <div>
    <incident-update-status-dialog 
        :dialog="showUpdateStatusDialog"
        :incident-id="selectedIncidentId"
        @on-success="onUpdatedStatusSuccess"
        @on-close="showUpdateStatusDialog = false;"
    />
    <incident-new-dialog 
        :dialog="showNewDialog"
        @on-success="onNewIncidentSuccess"
        @on-close="showNewDialog = false;"/>
    <v-card class="border-radius-xl">
      <v-card-text class="px-0 py-0">
        <v-data-table
            :loading="isLoading"
            :options.sync="options"
            :headers="headers"
            :items="items"
            :items-per-page="itemsPerPage"
            :server-items-length="totalItems"
            :page.sync="page"
            :search="search"
            class="table border-radius-xl"
            hide-default-footer
            mobile-breakpoint="0"
            :show-expand="showExpand"
        >
          <template v-slot:top>
            <incidents-table-toolbar @on-filter-selected="onFilterSelected" @on-new-incident="onNewIncident"/>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <notice-table :incident-id="item.id"/>
            </td>
          </template>
          <template v-slot:item.actions="{ item }" class="d-flex">
            <v-icon
                class="material-icons-round mr-2"
                @click="openUpdateStatusDialog(item.id)"
            >
              add_task
            </v-icon>
            <v-icon
                v-if="!item.isExternal && isAdmin"
                small
                class="material-icons-round"
                @click="deleteItem(item)"
            >
              delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col class="d-flex align-center" cols="6" lg="3">
            <span class="text-body me-3 mb-5 text-sm">Elementos por página:</span>
            <v-select
                v-model="itemsPerPage"
                dense
                filled
                solo
                :items="[5,10,20,50]"
            ></v-select>
          </v-col>
          <v-col class="ml-auto d-flex justify-end" cols="6">
            <v-pagination
                v-model="page"
                :length="pageCount"
                circle
                class="pagination"
                next-icon="fa fa-angle-right"
                prev-icon="fa fa-angle-left"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import NoticeTable from "@/views/Pages/Incidents/Widgets/NoticeTable";
import IncidentsTableToolbar from "@/views/Pages/Incidents/Widgets/IncidentsTableToolbar";
import incidentService from "@/services/incident.service";
import IncidentUpdateStatusDialog from "@/views/Pages/Incidents/Widgets/IncidentUpdateStatusDialog";
import IncidentNewDialog from "@/views/Pages/Incidents/Widgets/IncidentNewDialog";
// eslint-disable-next-line no-unused-vars
import userService from "@/services/user.service";
import loginService from "@/services/login.service";

export default {
  name: "IncidentsTable",
  components: {
    IncidentsTableToolbar,
    NoticeTable,
    IncidentUpdateStatusDialog,
    IncidentNewDialog
  },
  props:{
    title:{
      type: String,
      default: ""
    },
    search:{
      type: String,
      default: ""
    },
  },
  data: () => ({
    showExpand: true,
    isLoading: false,
    lastFilters: {},
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    options: {},
    showUpdateStatusDialog: false,
    showNewDialog: false,
    selectedIncidentId: 0,
    showDialog: false,
    items: [],
    totalItems: 0,
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: "Origen",
        value: "origin",
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: "Estatus",
        value: "status.name",
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: "Folio",
        value: "folio",
        class: "inline text-no-wrap",
      },
      {
        text: "Fecha Creación",
        value: "createDateFormat",
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: "Fecha Actualización",
        value: "updateDateFormat",
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: "Ultima Modificación",
        value: "lastModificationTimeFormat",
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: "Autopista",
        value: "highway.name",
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: "Cuerpo",
        value: "side.name",
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: "Sentido",
        value: "direction.name",
        class: "text-secondary font-weight-bolder opacity-7",
      },
      {
        text: "Carril",
        value: "lane.name",
        class: "text-secondary font-weight-bolder opacity-7",
      },
      // {
      //   text: "Latitude",
      //   value: "latitude",
      //   class: "text-secondary font-weight-bolder opacity-7",
      // },
      // {
      //   text: "Longitude",
      //   value: "longitude",
      //   class: "text-secondary font-weight-bolder opacity-7",
      // },
      { text: 'Acciones', value: 'actions', sortable: false },
    ]
  }),
  computed:{
    isAdmin(){
      return loginService.isAdmin();
    }
  },
  mounted() {
    this.loadData()
  },
  watch:{
    // eslint-disable-next-line no-unused-vars
    itemsPerPage(val){
      this.loadData()
    },
    search(val){
      this.$emit("on-search", val);
    },
    options(val) {
      console.log(val);
      this.loadData()
    }
  },
  methods:{
    loadData(){
      
      const filters = this.lastFilters;
      const offset = (this.page - 1) * this.itemsPerPage;
      
      this.isLoading = true;
      
      const data = {
        SkipCount: offset,
        MaxResultCount: this.itemsPerPage,
      };
      
      if(filters.highwayId)
        data['HighwayId'] = filters.highwayId;

      if(filters.statusId)
        data['StatusId'] = filters.statusId;

      if(filters.origin)
        data['Origin'] = filters.origin;

      if(filters.startDate)
        data['StartDate'] = filters.startDate;

      if(filters.endDate)
        data['EndDate'] = filters.endDate;

      if(filters.text)
        data['Text'] = filters.text;

      if(this.options.sortBy.length > 0) {
        data['Sorting'] = this.options.sortBy[0];
        data['SortDirection'] = this.options.sortDesc[0] ? "descending" : "";
      }

      // if(filter)
      //   data['Filter'] = filter;
      
      incidentService.getIncidents(data).then(d =>{        
        this.pageCount = Math.ceil(d.totalCount / this.itemsPerPage);
        this.items = d.items;
        this.totalItems = d.totalCount;
        this.isLoading = false;
      })
    },
    onFilterSelected(data){
      this.lastFilters = data;
      this.page = 1;
      this.loadData();
    },
    // eslint-disable-next-line no-unused-vars
    onNewIncident(data){
      this.showNewDialog = true;
    },
    onNewIncidentSuccess(data){
      this.showNewDialog = false;
      this.$swal(`Incidencia creada [${data.id}]`,"","success").then(() =>{
        this.loadData();
      });
    },
    // eslint-disable-next-line no-unused-vars
    onUpdatedStatusSuccess(data){
      this.showUpdateStatusDialog = false;
      this.$swal(`Estatus actualizado [${this.selectedIncidentId}]`,"","success").then(() =>{
        this.loadData();
      });
    },
    deleteItem(item){
      this.$swal({
        title: `Estas seguro que deseas eliminar [${item.id}] ${this.title}?`,
        text: "No se permite revertir esta acción!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar'
      }).then((result) => {
        if (result.isConfirmed) {
          incidentService.deleteIncident(item.id).then(() =>{
            this.loadData();
          });
        }
      })
    },
    openUpdateStatusDialog(incidentId){
      this.selectedIncidentId = incidentId;
      this.showUpdateStatusDialog = true;
    }
  }
}
</script>

<style scoped>

</style>
