<template>
  <v-dialog
      v-model="isVisible"
      width="800"
      scrollable
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Actualizar Estatus {{incidentId}}
          </v-col>
          <v-col>
            <v-chip v-if="!isLoading && incident.status" class="float-end">
              {{incident.status.name}}
            </v-chip>
            <v-progress-circular
                v-else
                indeterminate
                color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 500px;">
        <v-container>
          <v-row v-if="showError">
            <v-col>
              <v-alert
                  type="error"
                  variant="outlined"
              >
                {{message}}
              </v-alert>
            </v-col>
          </v-row>
          
          <v-row v-if="incident">
            <v-col>
              <v-list-item>
<!--                <v-list-item-avatar>-->
<!--                  <v-img :src="item.avatar"></v-img>-->
<!--                </v-list-item-avatar>-->

                <v-list-item-content v-if="incident.highway">
                  <v-list-item-title>Autopista</v-list-item-title>
                  <v-list-item-subtitle v-html="incident.highway.name"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item>
                <!--                <v-list-item-avatar>-->
                <!--                  <v-img :src="item.avatar"></v-img>-->
                <!--                </v-list-item-avatar>-->

                <v-list-item-content v-if="incident.lane">
                  <v-list-item-title>Carril</v-list-item-title>
                  <v-list-item-subtitle v-html="incident.lane.name"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item>
                <!--                <v-list-item-avatar>-->
                <!--                  <v-img :src="item.avatar"></v-img>-->
                <!--                </v-list-item-avatar>-->

                <v-list-item-content v-if="incident.side">
                  <v-list-item-title>Cuerpo</v-list-item-title>
                  <v-list-item-subtitle v-html="incident.side.name"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item>
                <!--                <v-list-item-avatar>-->
                <!--                  <v-img :src="item.avatar"></v-img>-->
                <!--                </v-list-item-avatar>-->

                <v-list-item-content v-if="incident.direction">
                  <v-list-item-title>Dirección</v-list-item-title>
                  <v-list-item-subtitle v-html="incident.direction.name"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row v-if="incident.initialComment">
            <v-col>
              <v-list-item>
                <!--                <v-list-item-avatar>-->
                <!--                  <v-img :src="item.avatar"></v-img>-->
                <!--                </v-list-item-avatar>-->

                <v-list-item-content>
                  <v-list-item-title>Comentario Inicial</v-list-item-title>
                  <v-list-item-subtitle v-html="incident.initialComment"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          
          <v-divider />

          <location-control class="mt-2" @on-location="onLocation" />
          
          <v-row no-gutters class="mt-2">
            <v-col
                cols="12"
            >
              <v-select
                  solo
                  label="Nuevo estatus" 
                  :items="statuses" 
                  item-text="name" 
                  item-value="id"
                  v-model="v$.model.selectedId.$model"
                  :error="v$.model.selectedId.$error"
                  :error-messages="selectedId"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                  solo
                  name="input-7-4"
                  label="Comentarios"
                  v-model="v$.model.comment.$model"
                  :error="v$.model.comment.$error"
                  :error-messages="comment"
              ></v-textarea>
            </v-col>
            <v-col
                cols="12"
            >
              <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  @change="selectedPicture"
              >
              <v-btn block @click="$refs.uploader.click()" :loading="isFileUploading">
                Buscar imagen
              </v-btn>              
            </v-col>
          </v-row>
          <notice-pictures :images="model.images" :edition="true" allow-delete @on-deleted="onDeletedImage" />         
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
            color="blue-darken-1"
            text
            @click="save()"
            :loading="isSaving"
        >
          Guardar
        </v-btn>
        <v-btn
            color="blue-darken-1"
            text
            @click="close()"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {email, minLength, required, sameAs} from '@vuelidate/validators'
import userService from "@/services/user.service";
import catalogsService from "@/services/catalogs.service";
import incidentService from "@/services/incident.service";
import fileService from "@/services/file.service";
import NoticePictures from "@/views/Pages/Incidents/Widgets/NoticePictures";
import LocationControl from "@/views/Pages/Incidents/Widgets/LocationControl";


export default {  
  name: "CatalogsNewDialog",
  components:{
    NoticePictures,
    LocationControl
  },
  props:{
    dialog: {
      type: Boolean,
      default: false
    },
    incidentId: {
      type: Number,
      default: null
    },
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      model: {
        selectedId: { required },
        comment: { required },
      },
    }
  },
  data() {
    return {
      isLoading: false,
      isDeleting: false,
      isFileUploading: false,
      isSaving: false,
      showError: false,
      message: "",
      incident: {},
      statuses: [],
      model:{
        selectedId: "",
        comment: "",
        images: [],
        latitude: null,
        longitude: null
      }
    };
  },
  computed:{
    isVisible(){
      return this.dialog
    },
    selectedId(){
      return this.v$.model.selectedId.$errors.map(err => err.$message);
    },
    comment(){
      return this.v$.model.comment.$errors.map(err => err.$message);
    }
  },
  watch:{
    dialog(val){
      if(val){
        this.loadIncident();
        this.$emit("on-open", val);
      }else {
        this.clearForm();
        this.$emit("on-close", val);
      }
    },
    incidentId(){
      this.loadIncident();
    }
  },
  mounted() {
  },
  methods:{
    close(){
      this.clearForm();
      this.$emit("on-close", false);
    },
    clearForm(){
      this.showError = false;
      this.model = {
        selectedId: "",
        comment: "",
        images: [],
      }
    },
    onDeletedImage(e){
      this.model.images = e;
    },
    selectedPicture(e){
      if (this.$refs.uploader.files.length) {
        this.isFileUploading = true

        var formData = new FormData()
        formData.append('formData', this.$refs.uploader.files[0])

        fileService.uploadFile(formData).then(d => {
          if(d.data.items){
            d.data.items.forEach(i => {
              this.model.images.push({
                id: i.id
              })
            })
          }
          
          this.isFileUploading = false
        })

        e.target.value = "";
      }
    },
    deleteFile(fileId){
      this.isDeleting = true;
      fileService.deleteFile(fileId).then(d =>{
        this.model.images = this.model.images.filter(i => i.id !== fileId)
        this.isDeleting = false;
      })
    },
    loadStatuses(){
      incidentService.getStatusesByCurrent(this.incident.incidentStatusId).then(d =>{
        this.statuses = d.items;
      })
    },
    loadIncident(){
      this.isLoading = true;
      incidentService.getIncident(this.incidentId).then(d =>{
        this.incident = d;
        this.isLoading = false;
        
        this.loadStatuses();
      })
    },
    onLocation(data){
      this.model.latitude = data.lat;
      this.model.longitude = data.lng;
    },
    save(){
      this.message = "";
      this.showError = false;

      this.v$.$validate().then(isValid =>{
        if(isValid){
          this.isSaving = true;

          const data = {
            newIncidentStatusId: this.model.selectedId,
            comment: this.model.comment,
            images: this.model.images,
            latitude: this.model.latitude,
            longitude: this.model.longitude
          }
          
          return incidentService.updateStatus(this.incidentId, data)
        }
      }).then(d =>{
        if(d) {
          this.clearForm();
          this.$emit("on-success", false);
        }
      }).catch(d =>{
        if(d.error){
          this.message = d.error.message;
          this.showError = true;
        }
      }).finally(() =>{
        this.isSaving = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
