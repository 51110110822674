<template>
  <v-dialog
      v-model="isVisible"
      width="800"
      scrollable
  >
    <v-card>
      <v-card-title>Detalle</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 500px;">
        <v-container>
          <v-row v-if="showError">
            <v-col>
              <v-alert
                  type="error"
                  variant="outlined"
              >
                {{message}}
              </v-alert>
            </v-col>
          </v-row>

          <v-row v-if="notice">
            <v-col>
              <v-list-item>
                <v-list-item-content v-if="notice.comment">
                  <v-list-item-title>Comentarios</v-list-item-title>
                  <v-list-item-subtitle v-html="notice.comment" />
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item>
                <v-list-item-content v-if="notice.creationTimeFormat">
                  <v-list-item-title>Fecha</v-list-item-title>
                  <v-list-item-subtitle v-html="notice.creationTimeFormat" />
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          
          <v-divider />
          
          <notice-pictures :images="images" />
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
            color="blue-darken-1"
            text
            @click="close()"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import NoticePictures from "@/views/Pages/Incidents/Widgets/NoticePictures";
import noticeService from "@/services/notice.service";


export default {
  name: "NoticeDetailDialog",
  components:{
    NoticePictures
  },
  props:{
    dialog: {
      type: Boolean,
      default: false
    },
    noticeId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      showError: false,
      message: "",
      images: [],
      notice: {},
    };
  },
  computed:{
    isVisible(){
      return this.dialog
    },
  },
  watch:{
    dialog(val){
      if(val){
        this.$emit("on-open", val);
      }else {
        this.$emit("on-close", val);
      }
    },
    noticeId(){
      this.loadNotice();
    }
  },
  mounted() {
  },
  methods:{
    close(){
      this.$emit("on-close", false);
    },
    loadNotice(){
      noticeService.getNotice(this.noticeId).then(d =>{
        this.notice = d;
        this.images = d.files.map(f => {
          return {
            id: f.id,
            caption: f.caption
          }
        });
      })
    },
  }
}
</script>

<style scoped>

</style>
