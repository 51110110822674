<template>
  <v-row>
    <v-col
        v-for="image in images"
        :key="image.id"
        class="d-flex child-flex"
        cols="4"
    >
      <v-hover :disabled="!allowDelete && !image.isExternal">
        <template v-slot:default="{ hover }">
          <div>
            <v-img
                @click="!allowDelete ? openImage(`${baseUrl}/api/app/file/${image.id}`) : () => {}"
                :src="`${baseUrl}/api/app/file/${image.id}`"
                :lazy-src="`${baseUrl}/api/app/file/${image.id}`"
                aspect-ratio="1"
                class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-fade-transition>
                <v-overlay
                    v-if="hover"
                    absolute
                    color="#036358"
                >
                  <v-btn :loading="isDeleting" color="red" @click="deleteFile(image.id)">Eliminar</v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-img>
            <v-text-field v-model="image.caption" v-if="edition" solo/>
            <label v-else>{{image.caption}}</label>
          </div>
        </template>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import fileService from "@/services/file.service";

export default {
  name: "NoticePictures",
  props: {
    images: {
      type: Array,
      default: []
    },
    allowDelete: {
      type: Boolean,
      default: false
    },
    edition: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isDeleting: false,
  }),
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },   
  },
  watch:{
    images(val){
      console.log(val)
    }
  },
  methods: {
    deleteFile(fileId) {
      this.isDeleting = true;
      fileService.deleteFile(fileId).then(() => {
        this.$emit("on-deleted", this.images.filter(i => i.id !== fileId));
        this.isDeleting = false;
      })
    },
    openImage(url){
      window.open(url, '_blank').focus();
    }
  }
}
</script>

<style scoped>

</style>
