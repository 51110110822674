<template>
  <v-dialog
      v-model="isVisible"
      width="800"
      scrollable
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Nuevo Incidente
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 500px;">
        <v-container>
          <v-row v-if="showError">
            <v-col>
              <v-alert
                  type="error"
                  variant="outlined"
              >
                {{message}}
              </v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-menu
                  ref="dateMenu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="model.selectedDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="model.selectedDate"
                      label="Fecha creación"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      solo
                      clearable
                      :error-messages="selectedDate"
                  >
                    <template v-slot:prepend>
                      <v-icon
                          class="material-icons-round mt-1"
                          color="#adb5bd">
                        calendar_month
                      </v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="model.selectedDate"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="dateMenu = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dateMenu.save(model.selectedDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-select
                  solo
                  label="Autopista"
                  :items="highwayItems"
                  item-text="name"
                  item-value="id"
                  v-model="v$.model.selectedHighway.$model"
                  :error="v$.model.selectedHighway.$error"
                  :error-messages="selectedHighway"
              ></v-select>
            </v-col>  
            <v-col>
              <v-text-field v-model="model.folio" label="Folio" solo />
            </v-col>
          </v-row>
          <v-row class="mt-n5">
            <v-col>
              <v-select
                  solo
                  label="Cuerpo"
                  :items="sideItems"
                  item-text="name"
                  item-value="id"
                  v-model="v$.model.selectedSide.$model"
                  :error="v$.model.selectedSide.$error"
                  :error-messages="selectedSide"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                  solo
                  label="Carril"
                  :items="laneItems"
                  item-text="name"
                  item-value="id"
                  v-model="v$.model.selectedLane.$model"
                  :error="v$.model.selectedLane.$error"
                  :error-messages="selectedLane"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                  solo
                  label="Sentido"
                  :items="directionItems"
                  item-text="name"
                  item-value="id"
                  v-model="v$.model.selectedDirection.$model"
                  :error="v$.model.selectedDirection.$error"
                  :error-messages="selectedDirection"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-n5">
            <v-col>
              <v-select
                  solo
                  label="Tipo"
                  :items="typeItems"
                  item-text="description"
                  item-value="id"
                  v-model="v$.model.selectedType.$model"
                  :error="v$.model.selectedType.$error"
                  :error-messages="selectedType"
              ></v-select>
            </v-col>
          </v-row>
          
          <location-control @on-location="onLocation"/>
          
          <v-row no-gutters class="mt-2">
            <v-col cols="12">
              <v-textarea
                  solo
                  name="input-7-4"
                  label="Comentarios"
                  v-model="v$.model.comment.$model"
                  :error="v$.model.comment.$error"
                  :error-messages="comment"
              ></v-textarea>
            </v-col>
            <v-col
                cols="12"
            >
              <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  @change="selectedPicture"
              >
              <v-btn block @click="$refs.uploader.click()" :loading="isFileUploading">
                Buscar imagen
              </v-btn>
            </v-col>
          </v-row>
          <notice-pictures :images="model.images" :edition="true" allow-delete @on-deleted="onDeletedImage" />
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
            color="blue-darken-1"
            text
            @click="save()"
            :loading="isSaving"
        >
          Save
        </v-btn>
        <v-btn
            color="blue-darken-1"
            text
            @click="close()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {email, minLength, required, sameAs} from '@vuelidate/validators'
import userService from "@/services/user.service";
import catalogsService from "@/services/catalogs.service";
import incidentService from "@/services/incident.service";
import fileService from "@/services/file.service";
import NoticePictures from "@/views/Pages/Incidents/Widgets/NoticePictures";
import LocationControl from "@/views/Pages/Incidents/Widgets/LocationControl";


export default {
  name: "IncidentNewDialog",
  components:{
    NoticePictures,
    LocationControl
  },
  props:{
    dialog: {
      type: Boolean,
      default: false
    },
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      model: {
        selectedDate: { required },
        selectedHighway: { required },
        selectedSide: { required },
        selectedLane: { required },
        selectedDirection: { required },
        selectedType: { required },
        comment: { required },
      },
    }
  },
  data() {
    return {
      dateMenu: false,
      isLoading: false,
      isDeleting: false,
      isFileUploading: false,
      isSaving: false,
      showError: false,
      message: "",
      highwayItems: [],
      typeItems: [],
      sideItems: [],
      laneItems: [],
      directionItems: [],
      latitude: null,
      longitude: null,
      model:{
        folio: null,
        selectedDate: null,
        selectedHighway: null,
        selectedType: null,
        selectedSide: null,
        selectedLane: null,
        selectedDirection: null,
        comment: "",
        images: [],        
      }
    };
  },
  computed:{
    isVisible(){
      return this.dialog
    },
    selectedDate(){
      return this.v$.model.selectedDate.$errors.map(err => err.$message);
    },
    selectedHighway(){
      return this.v$.model.selectedHighway.$errors.map(err => err.$message);
    },
    selectedSide(){
      return this.v$.model.selectedSide.$errors.map(err => err.$message);
    },
    selectedLane(){
      return this.v$.model.selectedLane.$errors.map(err => err.$message);
    },
    selectedDirection(){
      return this.v$.model.selectedDirection.$errors.map(err => err.$message);
    },
    selectedType(){
      return this.v$.model.selectedType.$errors.map(err => err.$message);
    },
    comment(){
      return this.v$.model.comment.$errors.map(err => err.$message);
    }
  },
  watch:{
    dialog(val){
      if(val){
        this.$emit("on-open", val);
      }else {
        this.clearForm();
        this.$emit("on-close", val);
      }
    },
    incidentId(){
      this.loadIncident();
    }
  },
  mounted() {
    this.loadHighways();
    this.loadSides();
    this.loadLanes();
    this.loadDirections();
    this.loadTypes();   
  },
  methods:{
    loadHighways() {
      catalogsService.getHigways(0,100).then(d => {
        this.highwayItems = d.items
      })
    },
    loadSides() {
      catalogsService.getSide(0,100).then(d => {
        this.sideItems = d.items
      })
    },
    loadLanes() {
      catalogsService.getLanes(0,100).then(d => {
        this.laneItems = d.items
      })
    },
    loadDirections() {
      catalogsService.getDirections(0,100).then(d => {
        this.directionItems = d.items
      })
    },
    loadTypes(){
      incidentService.getTypes().then(d =>{
        this.typeItems = d.items;
      })
    },
    close(){
      this.clearForm();
      this.$emit("on-close", false);
    },
    clearForm(){
      this.showError = false;
      this.model = {
        folio: null,
        selectedDate: null,
        selectedHighway: null,
        selectedType: null,
        selectedSide: null,
        selectedLane: null,
        selectedDirection: null,
        comment: "",
        images: [],
      }
    },
    onDeletedImage(e){
      this.model.images = e;
    },
    selectedPicture(e){
      if (this.$refs.uploader.files.length) {
        this.isFileUploading = true

        var formData = new FormData()
        formData.append('formData', this.$refs.uploader.files[0])

        fileService.uploadFile(formData).then(d => {
          if(d.data.items){
            d.data.items.forEach(i => {
              this.model.images.push({
                id: i.id
              })
            })
          }

          this.isFileUploading = false
        })

        e.target.value = "";
      }
    },
    deleteFile(fileId){
      this.isDeleting = true;
      fileService.deleteFile(fileId).then(d =>{
        this.model.images = this.model.images.filter(i => i.id !== fileId)
        this.isDeleting = false;
      })
    },
    onLocation(data){
      this.latitude = data.lat;
      this.longitude = data.lng;
    },
    save(){
      this.message = "";
      this.showError = false;

      this.v$.$validate().then(isValid =>{
        if(isValid){
          this.isSaving = true;

          const data = {
            createDate: this.model.selectedDate,
            incidentTypeId: this.model.selectedType,
            highwayId : this.model.selectedHighway,
            sideId: this.model.selectedSide,
            laneId: this.model.selectedLane,
            directionId: this.model.selectedDirection,
            folio: this.model.folio,
            initialComment: this.model.comment,
            images: this.model.images,
            latitude: this.latitude,
            longitude: this.longitude
          }

          return incidentService.createIncident(data)
        }
      }).then(d =>{
        if(d) {
          this.clearForm();
          this.$emit("on-success", d);
        }
      }).catch(d =>{
        if(d.error){
          this.message = `${d.error.message} [${d.error.details}]`;
          this.showError = true;
        }
      }).finally(() =>{
        this.isSaving = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
