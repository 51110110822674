<template>
  <div>
    <notice-detail-dialog :dialog="showDialogNoticeDetail" :notice-id="selectedNoticeId" @on-close="showDialogNoticeDetail=false" />
    <v-card class="border-radius-xl">
      <v-card-text class="px-0 py-0">
        <v-data-table
            :loading="isLoading"
            :options.sync="options"
            :headers="headers"
            :items="items"
            :items-per-page="itemsPerPage"
            :server-items-length="totalItems"
            :page.sync="page"
            class="table border-radius-xl"
            hide-default-footer
            mobile-breakpoint="0"
        >
          <template v-slot:top>
            <div>
              <h3>Detalle</h3>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="material-icons-round mr-2"
                @click="showNoticeDetail(item.id)"
            >
              photo_library
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col class="d-flex align-center" cols="6" lg="3">
            <span class="text-body me-3 mb-5 text-sm">Elementos por página:</span>
            <v-select
                v-model="itemsPerPage"
                dense
                filled
                solo
                :items="[5,10,20,50]"
            ></v-select>
          </v-col>
          <v-col class="ml-auto d-flex justify-end" cols="6">
            <v-pagination
                v-model="page"
                :length="pageCount"
                circle
                class="pagination"
                color="#d81b60"
                next-icon="fa fa-angle-right"
                prev-icon="fa fa-angle-left"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import noticeService from "@/services/notice.service";
import NoticeDetailDialog from "@/views/Pages/Incidents/Widgets/NoticeDetailDialog";

export default {
  name: "NoticeTable",
  components:{
    NoticeDetailDialog
  },
  props:{
    incidentId:{
      type: Number,
      default: null
    }
  },
  data: () => ({
    isLoading: false,
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    options: {},
    showDialogNoticeDetail: false,
    selectedNoticeId: 0,
    items: [],
    totalItems: 0,
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: "Fecha creación",
        align: "start",
        sortable: false,
        value: "creationTimeFormat",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: "Comentarios",
        align: "start",
        sortable: false,
        value: "comment",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      { text: 'Acciones', value: 'actions', sortable: false },
    ]
  }),
  mounted() {
    this.loadData();
  },
  watch:{
    itemsPerPage(val){
      this.loadData()
    },
    search(val){
      this.$emit("on-search", val);
    },
    options(val) {
      this.loadData()
    }
  },
  methods:{
    loadData(){
      const filters = this.lastFilters;
      const offset = (this.page - 1) * this.itemsPerPage;

      this.isLoading = true;

      const data = {
        IncidentId: this.incidentId,
        SkipCount: offset,
        MaxResultCount: this.itemsPerPage,
      };

      if(this.options.sortBy.length > 0) {
        data['Sorting'] = this.options.sortBy[0];
        data['SortDirection'] = this.options.sortDesc[0] ? "descending" : "";
      }

      noticeService.getNotices(data).then(d =>{
        this.pageCount = Math.ceil(d.totalCount / this.itemsPerPage);
        this.items = d.items;
        this.totalItems = d.totalCount;
        this.isLoading = false;
      })
    },
    showNoticeDetail(noticeId){
      this.selectedNoticeId = noticeId;
      this.showDialogNoticeDetail = true;
    }
  }
}
</script>

<style scoped>

</style>
