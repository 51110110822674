import http, { e } from './http'
import loginService from './login.service'

const fileService = {
  uploadFile (file) {
    const token = loginService.getRawToken()

    var headers = {
      Authorization: 'Bearer ' + token,
    }

    return http.upload(e('app/file'), file, headers)
  },
  deleteFile(fileId){
    const token = loginService.getRawToken()

    var headers = {
      Authorization: 'Bearer ' + token,
    }

    return http.remove(e(`app/file/${fileId}`), headers)
  }
}

export default fileService
