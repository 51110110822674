<template>
  <v-container class="py-6" fluid>   
    <v-row class="mt-0">
      <v-col cols="12">
        <incidents-table/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import IncidentsTable from "./Widgets/IncidentsTable.vue";

export default {
  name: "Incidents",
  components: {
    IncidentsTable,
  },
  data: () =>({
    drawer: true
  })
}
</script>

<style scoped>

</style>
