<template>
<v-row>
  <v-col>
    <v-text-field
        v-model="latitude"
        label="Latitude"
        hint="Latitude"
        persistent-hint
        disabled
        solo
        dense
    ></v-text-field>
  </v-col>
  <v-col>
    <v-text-field
        v-model="longitude"
        label="Longitude"
        hint="Longitude"
        persistent-hint
        disabled
        solo
        dense
    ></v-text-field>
  </v-col>
  <v-col>
    <v-btn :loading="isLoading" @click="getLocation" block>
      Obtener Geoloalización
    </v-btn>
  </v-col>
</v-row>
</template>

<script>
export default {
  name: "LocationControl.vue",
  data() {
    return {
      isLoading: false,
      latitude:0,
      longitude: 0
    };
  },
  methods:{
    getLocation(){
      this.isLoading = true;
      
      this.$getLocation({
        enableHighAccuracy: true, //defaults to false
      }).then(coordinates => {
        this.$emit("on-location", coordinates);
        
        this.latitude = coordinates.lat;
        this.longitude = coordinates.lng;
        
        console.log(coordinates);            
      }).finally(() =>{
        this.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
